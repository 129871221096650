import SidebarContentCollapsedPage from '../Page';
import { useUserAccount } from '@/api/user';

export default function ReferPage() {
  const { isAuthorized } = useUserAccount();
  return (
    <SidebarContentCollapsedPage
      page={{
        label: 'reference_code',
        icon: 'fa-solid fa-gift fa-swap-opacity',
        paths: [isAuthorized ? '/affiliates' : '/affiliates/panel'],
      }}
      isHightligh
    />
  );
}
